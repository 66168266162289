import React from "react"
import PropTypes from "prop-types"
import Layout from '../components/Layout';
// Utilities
import kebabCase from "lodash/kebabCase"
// Components
import { Link, graphql } from "gatsby"
import Block from "../components/Block"
import Tags from "../components/Tags"

const TagsPage = ({
	location, 
	data: {
		allMarkdownRemark: { group },
		site: {
			siteMetadata: { title },
		},
	}
}) => {

	return (
		<Layout location={location} title={title}>
			<Block size="small">
				<h1>Tags</h1>
				<Tags>
					{group.map(tag => (
						<li key={tag.fieldValue}>
							<Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
								{tag.fieldValue}({tag.totalCount})
							</Link>
						</li>
					))}
				</Tags>
			</Block>
		</Layout>
	)
};
TagsPage.propTypes = {
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			group: PropTypes.arrayOf(
				PropTypes.shape({
					fieldValue: PropTypes.string.isRequired,
					totalCount: PropTypes.number.isRequired,
				}).isRequired
			),
		}),
		site: PropTypes.shape({
			siteMetadata: PropTypes.shape({
				title: PropTypes.string.isRequired,
			}),
		}),
	}),
}
export default TagsPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`